import { navigate } from "gatsby"
import firebase from "firebase"

import { parseAuthErrorMessage } from "./errors"
import { sendOTP } from "./signin"

export const handleAuthSubmit = async ({ payload, zendeskId }) => {
  let { setLoading, setMessage, otpVerifier, authDispatch } = payload
  let mobileNumber = payload?.values?.mobileNumber
  let otp = payload?.values?.otp
  mobileNumber = `+63${mobileNumber}`

  switch (payload.module) {
    // This runs when the page is on input number
    case "input-number":
      setLoading(true)
      let tempOtpVerifier = await sendOTP({
        mobileNumber,
      })
      setLoading(false)
      authDispatch({
        type: "SAVE_DETAILS",
        payload: {
          otpVerifier: tempOtpVerifier,
          mobileNumber: payload?.values?.mobileNumber,
        },
      })
      payload.resetForm()
      navigate("/epharmacy/verify-otp")
      break

    // This runs when the page is on verify OTP
    case "verify-otp":
      try {
        let response = await otpVerifier?.confirm(otp) // This creates the firebase:authUser in sessionStorage
        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION) // This defines where the firebase:authUser is stored
        if (response) {
          navigate(
            `/epharmacy/proof-of-payment${!!zendeskId && `?z=${zendeskId}`}`
          )
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message: parseAuthErrorMessage(error.message, {
              authDispatch,
              mobileNumber,
              setMessage,
            }),
          },
        })
      }
      break

    default:
      break
  }
}

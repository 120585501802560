import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"

import Layout from "layout/Layout"
import AuthForm from "./AuthForm"
import Button from "elements/Button"
import Message from "elements/Message"
import Container from "layout/Container"

import { AppContext } from "context/AppContext"
import { AuthContext } from "context/AuthContext"
import { authValidationSchema } from "./utils/authValidationSchema"
import { handleAuthSubmit } from "./services/auth"
import { sendOTP } from "./services/signin"

const MobileVerification = (props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const { dispatch } = useContext(AppContext)
  const { authState, authDispatch } = useContext(AuthContext)

  const urlSearchParams = new URLSearchParams(props?.location?.search)
  const zendeskId = urlSearchParams.get("z") || ""

  let { pageContext, location } = props
  let { module } = pageContext

  const handleSubmit = (values, { resetForm }) => {
    setMessage({})
    setLoading(true)

    let payload = {
      values,
      resetForm,
      setMessage,
      setLoading,
      otpVerifier: authState?.otpVerifier || null,
      authDispatch,
      module: module?.name,
    }
    handleAuthSubmit({
      payload,
      state: authState,
      dispatch,
      location,
      zendeskId,
    })
  }

  const handleRenderOTPPage = async () => {
    setMessage({})
    setLoading(true)
    let mobileNumber = authState?.mobileNumber
    mobileNumber = `+63${mobileNumber}`
    setLoading(true)
    let tempOtpVerifier = await sendOTP({
      mobileNumber,
    })
    setLoading(false)
    authDispatch({
      type: "SAVE_DETAILS",
      payload: {
        otpVerifier: tempOtpVerifier,
      },
    })
  }

  useEffect(() => {
    if (module?.name === "verify-otp") {
      handleRenderOTPPage()
    }
  }, [authState.mobileNumber])

  const handleResendOTP = async ({ mobileNumber }) => {
    setMessage(null)

    let tempOtpVerifier = await sendOTP({
      mobileNumber,
    })

    authDispatch({
      type: "SAVE_DETAILS",
      payload: {
        otpVerifier: tempOtpVerifier,
      },
    })

    let resendCount = authState?.otpResendCount - 1
    authDispatch({
      type: "SET_OTP_RESEND_COUNT",
      payload: resendCount,
    })
    setMessage({
      type: "success",
      content: `OTP has been resent to your mobile number. You have ${resendCount} resend attempts remaining.`,
    })
  }

  return (
    <Layout
      title={module?.title}
      subtitle={module?.subtitle}
      seoTitle={module?.title}
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...authState, mobileNumber: authState.mobileNumber }}
          validationSchema={authValidationSchema(module?.name)}
          onSubmit={handleSubmit}
        >
          {({ values, errors, submitCount, setFieldValue }) => (
            <Form>
              <AuthForm
                module={module?.name}
                values={values}
                errors={errors}
                submitCount={submitCount}
                setFieldValue={setFieldValue}
                handleResendOTP={handleResendOTP}
              />
              {module?.name === "verify-otp" ? (
                <p className="is-size-6 mb-1">
                  Mobile number did not receive an OTP?{" "}
                  <a
                    onClick={async () =>
                      await handleResendOTP({
                        mobileNumber: `+63${authState?.mobileNumber}`,
                      })
                    }
                  >
                    Resend OTP
                  </a>
                </p>
              ) : null}

              {message?.content && (
                <Message color={message?.type}>
                  <div>{message?.content?.message || message?.content}</div>
                </Message>
              )}

              {errors?.length > 0 ? (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              ) : null}

              <Button
                type="submit"
                color="primary"
                className={classNames({ "is-loading": loading })}
                isFullwidth
              >
                {module?.cta}
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
      <div id="recaptcha-container"></div>
    </Layout>
  )
}

export default MobileVerification

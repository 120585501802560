import React from "react"
import Button from "../../Elements/Button"

export const authFormFields = (values) => {
  return [
    {
      type: "text",
      isNumeric: true,
      hasAddons: true,
      addonLeft: <Button className="is-static">🇵🇭 +63</Button>,
      name: "mobileNumber",
      label: "Mobile Number",
      isRequired: true,
      value: values?.mobileNumber,
      isFollowUpQuestion: false,
      placeholder: "9123456789",
      inclusions: ["input-number"],
      isNumeric: true,
      maxLength: 10,
    },
    {
      type: "text",
      isNumeric: true,
      name: "otp",
      label: "One-time PIN (OTP)",
      helper: `Enter the OTP sent to +63${values?.mobileNumber}. The OTP is only valid for one minute.`,
      isRequired: true,
      value: values?.otp,
      isFollowUpQuestion: false,
      placeholder: "••••••",
      inclusions: ["verify-otp"],
      isNumeric: true,
      maxLength: 6,
    }
  ]
}
